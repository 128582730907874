.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  height: 86px;
  width: 100%;
  background-color: rgba(9, 5, 13, 1);
  border-bottom: 1px solid;
  border-image: linear-gradient(rgba(38, 242, 189, 1), rgba(18, 38, 45, 1), rgba(38, 242, 189, 1)) 30;
  position: absolute;
  top: 0px;
  z-index: 9999;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.connect-button {
  margin: 22px;
}

.logo-header{
  margin-right: auto;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-header img{
  width: 100px;
  /* margin-right: auto;
  padding-left: 20px; */
}

.usdtBadge {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  border-radius: 12px;
  background-color: rgba(18, 38, 45, 1);
  color: #FFF;
  padding: 5px 15px;
}

.usdtBadge p {
  margin-bottom: 0;
}


.ftkxBadge {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  height: 40px;
  background-color: rgba(18, 38, 45, 1);
  color: #FFF;
  padding: 5px 15px;
}

.ftkxBadge p {
  margin-bottom: 0;
}

.ftkxBadge img {
  width: 20px;
}

.connectRepons {
  position: absolute;
  z-index: 5;
  top: 53px;
  left: 0;
  right: 0;
  display: flex;
  background-color: rgba(9, 5, 13, 1);
  padding-bottom: 15px;
  align-items: center;
  border-bottom: 1px solid;
  border-image: linear-gradient(rgba(38, 242, 189, 1), rgba(18, 38, 45, 1), rgba(38, 242, 189, 1)) 30;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 100%;
  gap: 15px;
  padding: 15px 10px;
  flex-wrap: wrap;
}

.logoF {
  width: 50px;
}

@media (max-width: 500px) {
  .connect-button div {
    font-size: 12px !important;
  }
}


@media (max-width: 805px) {
  .App-header {
    height: auto;
    justify-content: space-between;
    gap: 10px;
    padding: 5px 10px;
    align-items: flex-start;
    align-items: center;
  }

  .connect-button {
    margin: 5px;
  }
}