.danger_modal{
  border-radius: 20px;
  max-width: 464px;
  max-height: 380px;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 5, 13, 1);
  border: 1px solid rgba(211, 60, 60, 1);
  position: absolute!important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex!important;
  z-index: 999;
}

.danger_modal_bg{
  content: "";
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
  background-color: rgba(9, 5, 13, 0.8);
  position:absolute;
  display: flex!important;

  z-index: 99;
}

.danger_modal_header{
  color: rgba(211, 60, 60, 1);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  width: 100%;
}

.danger_modal_title{
  width: 100%;
  text-align: center;
  text-justify:auto;
  color: rgba(211, 60, 60, 1);
  margin: 20px 0;
}
.danger_modal_body{
  text-align: center;
  padding: 8px;
  color: #fff;
  padding-bottom: 50px;
}
.danger_modal_body > p {
  padding: 4px
}

.modal-content{
  height: 100%;
}

.tilt_animation{
  animation: tilt 0.7s infinite linear;
  position:relative;
}

@keyframes tilt {
  0% {
    transform:rotate(0deg);right: 0px;
  }
  25% {
     transform:rotate(30deg);right: -10px;
  }
  75% {
     transform:rotate(-30deg);right: 10px;
  }
  100% {
     transform:rotate(0deg);right: 0px;
  }
}

.close_button{
  width: 115px;
  height: 32px;
  right: 130px;
  bottom: 12px;
  position: absolute;
  background-color: rgb(156, 156, 156);
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: rgba(9, 5, 13, 1);;
}

.close_button:hover {
  opacity: 0.8;
}
.confirm_button{
  width: 115px;
  height: 32px;
  right: 12px;
  bottom: 12px;
  position: absolute;
  background-color: rgba(211, 60, 60, 1);
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: rgba(9, 5, 13, 1);;
}
.confirm_button:hover {
  opacity: 0.8;
}