* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.app-body {
  background: #0E0E0E;
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  background-image: url('/public/assets/bg.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-out;
  overflow-x: hidden;
}

main {
  width: 100%;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 15px;
  height: 100%;
}

.effect{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.effect img {
  width: 100%;
  height: 100vh;
}

.logoIntro {
  width: 200px;
  position: relative;
  top: 50%;
}

.logoIntro img:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%)
}

.logoIntro img:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.logo {
  height: auto;
}

.logo img {
  width: 100%;
}

.subtitle{
  font-weight: 200;
  font-size: 1.6em;
}

.textMain {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  max-width: 400px;
}

h1 {
  font-size: 3em;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  max-width: 330px;
}

.textMain h1 {
  font-size: 4em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  max-width: 330px;
}

.textMain p {
  color: #D9D9D9;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.button{
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btnConnect {
  border-radius: 50px;
  background: #e1ff2d;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 80%;
  max-width: 400px;
  padding: 10px 15px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}
.btnConnect:disabled {
  cursor:unset;
  background: #cecece;
}

.btnConnectWarning {
  border-radius: 50px;
  background: yellow;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding: 10px 15px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}
.btnConnectError {
  border-radius: 50px;
  background: red;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding: 10px 15px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}
.btnConnectError:disabled {
  border-radius: 50px;
  background: red;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding: 10px 15px;
  transition: all 0.3s ease;
  border: none;
  cursor:initial;
}


.btnConnect:hover {
  opacity: 0.8;
}

.inputContainer{
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
}
.textInputs {
  background: #e6fd65;
  border-radius: 10px;
  padding: 15px 15px;
  color: #000D14;
  border: none;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid transparent;
  position: relative;
}

.textInputsStaked {
  background: #e6fd65;
  border-radius: 10px;
  padding: 15px 15px;
  color: #000D14;
  border: none;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid transparent;
  position: relative;
  text-align: center;
}
.inputLabel {
  margin-top: 12px;
  margin-bottom: 4px;
}
wcm-modal {
  display: none!important;
}

.footer{
  width: 150px;
}
.footerImg{
  width: 100%;
}

.input-right{
  font-size: 1.5em;
}

.input-right-low{
  text-align: center;
}

.input-right-low-red{
  color: #ff4545;
  font-weight: 900;
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-title{
  margin-top: 100px;
}

.yellow-bar{
  background-color: #e1ff2d;
  width: 600%;
  color: black;
  height: 40px;
  font-size: 18px;
  position: relative;
  margin-left: -250%;
  text-align: center;
  text-justify: center;
  padding: 10px 0;

}
.nameContainer{
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: normal;
}

.flipContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mLogo{
  margin-right: 8px;
}

.walletBalance{
  font-size: 0.8em;
}

.leftContainer{
  justify-content: start;
  display: flex;
  width: 200px;
}